.rectangle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #272424; /* Couleur grise pour le rectangle */
  padding: 10px;
  margin: 20px auto; /* Centrer le conteneur */
  border-radius: 5px; /* Bordure légèrement arrondie */
  max-width: 300px; /* Ajustez la largeur maximale selon vos besoins */
  text-align: center; /* Centrer le texte */
}

.trustpilot-logo {
  width: 100%; /* Le logo s'adapte à la taille du conteneur */
  height: auto; /* Préserve les proportions de l'image */
}

.trustpilot-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.stars {
  display: flex;
  align-items: center;
}

.star {
  font-size: 2.5rem; /* Ajustez la taille des étoiles selon vos besoins */
  color: #00b67a; /* Couleur des étoiles */
  margin-right: 2px;
}

.filled {
  color: #00b67a; /* Couleur des étoiles remplies */
}

.half-filled {
  color: #00b67a; /* Couleur des étoiles à moitié remplies */
  background: linear-gradient(90deg, #00b67a 50%, #272424 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rating-text {
  margin-left: 5px;
  font-size: 1.2em; /* Ajustez la taille de la police selon vos besoins */
  color: black; /* Couleur noire pour le texte de la note */
}

.review-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 0.9em; /* Ajustez la taille de la police selon vos besoins */
}

.review-count, .rating-status {
  color: black; /* Couleur noire pour le texte des avis */
}

.rating-separator {
  margin: 0 5px;
  color: black; /* Couleur noire pour le séparateur */
}
