.sous_titre{
  color: rgb(218, 129, 27);
}


@media only screen and (max-width: 992px) {
  .about__page-content h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .about__page-img {
    display: none;
  }
}
