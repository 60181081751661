.thankyou__section {
    background: linear-gradient(rgb(0, 13, 107, 0.6), rgb(0, 13, 107, 0.6)),
      url("../assets/all-images/drive.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
    outline: none;
  }
  
/* Bouton */
.rectangle-container-new {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffbfb; /* Couleur grise pour le rectangle */
    padding: 10px;
    margin: 20px auto; /* Centrer le conteneur */
    border-radius: 5px; /* Bordure légèrement arrondie */
    max-width: 300px; /* Ajustez la largeur maximale selon vos besoins */
  }
  
  .trustpilot-logo {
    width: 100%; /* Le logo s'adapte à la taille du conteneur */
    height: auto; /* Préserve les proportions de l'image */
    outline: none;
  }

  .no-outline:focus{
    outline: none;
  }