.ri--vip-diamond-fill {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%2366ACDF' d='M4.878 3.003h14.254a1 1 0 0 1 .809.412l3.822 5.256a.5.5 0 0 1-.037.633l-11.354 12.3a.5.5 0 0 1-.735 0L.283 9.305a.5.5 0 0 1-.037-.633l3.823-5.256a1 1 0 0 1 .809-.412'/%3E%3C/svg%3E");
}

.header__top {
  padding: 10px 0px;
  background: #000d6b;
  color: #fff;
}

.header__top__left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.header__top__left span {
  font-size: 0.9rem;
}

.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

.header__top__help i {
  background: #fff;
  color: #000d6b;
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 0.7rem;
}

.header__top__right a {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
}

.header__top__right a:hover {
  color: #fff;
}

/* ============ header middle style =========== */

.header__middle {
  padding: 20px 0px;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  color: #000d6b;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #000d6b;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #000d6b;
}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  background: #7cd49a !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #ffffff;
  justify-content: end;
  font-size: 0.9rem;
}

.header__btn a:hover {
  color: #ffffff;
}

/* ================ main navbar styles =========== */

.main__navbar {
  padding: 15px 0px;
  background: #000d6b;
}

.mobile__menu i {
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
}

.nav__item {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item:hover {
  color: #f9a826;
}

.search__box {
  border: 1px solid #7c8a972f;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: #020a4de9;
}

.search__box input {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}



.nav__active {
  color: #f9a826;
}

/* Modal Style */

/* Styles pour la liste déroulante */
/* Styles pour la liste déroulante */
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown__menu {
  position: absolute;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px 0;
  z-index: 1001;
}

.dropdown__item {
  padding: 10px 20px;
  display: block;
  color: #000d6b;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
}

.dropdown__item:hover {
  background: #f9a826;
  color: #fff;
}
/* Improved Modal Style */

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.custom-modal-content {
  background: white;
  padding: 40px;  /* Augmenté pour plus d'espace intérieur */
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
  width: 90%;  /* Augmenté pour plus de largeur */
  max-width: 600px;  /* Augmenté pour un modal plus grand */
  animation: slideDown 0.3s ease-out;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.custom-modal-content label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.custom-modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-modal-content button {
  background: #000d6b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  display: block;
  margin: 0 auto;  /* Centré horizontalement */
}

.custom-modal-content button:hover {
  background: #001a8d;
}

.custom-modal-content .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
  transition: color 0.3s;
}

.custom-modal-content .close-btn:hover {
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
  .custom-modal-content {
    width: 95%;  /* Augmenté pour s'adapter à l'écran plus petit */
    padding: 30px;  /* Ajusté pour plus d'espace intérieur sur les petits écrans */
  }
}


/* Fin */


@media only screen and (max-width: 768px) {
  .header__top,
  .header__middle,
  .nav__right {
    display: none;
  }

  .mobile__menu {
    display: block;
  }

  .navigation {
    background: rgb(0, 13, 107, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #000d6b;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu span{
    color: #000d6b;
    font-weight: 600;
    font-size: 0.9rem;
  }
  
  .menu__active {
    display: block;
  }
}
