.stars {
  display: flex;
  gap: 0.2rem;
  color: #247739; /* Couleur des étoiles */
}

.stars .ri-star-fill,
.stars .ri-star-line {
  font-size: 1rem; /* Ajustez la taille des étoiles */
}

.testimonial {
  text-align: left; /* Alignement du texte à gauche */
}

.section__description {
  font-size: 1.1rem; /* Ajustez la taille de la police si nécessaire */
}

.mt-3 {
  margin-top: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.testimonial-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alignement à gauche */
  margin-top: 10px; /* Espacement au-dessus des informations */
}

.testimonial-info h6,
.testimonial-info p {
  text-align: left; /* Alignement à gauche */
}

.testimonial-info .stars {
  text-align: left; /* Alignement à gauche */
}

@media only screen and (max-width: 768px) {
  .testimonial p {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .testimonial h6 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .testimonial img {
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
  }
}
