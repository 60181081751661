.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 30% 50%; /* Forme d'œuf */
    padding: 15px 20px; /* Ajuster les paddings pour la forme d'œuf */
    cursor: pointer;
    z-index: 1000; /* Assurez-vous que le bouton est au-dessus des autres éléments */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Ajouter une ombre pour un effet de profondeur */
  }
  
  .whatsapp-button i {
    font-size: 35px;
  }
  