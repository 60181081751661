.car__item {
  border: 1px solid #7c8a9736;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the item stretches to full height */
}

.car__item-info span i {
  color: #f9a826;
  font-size: 1.1rem;
}

.car__item-btn {
  border-radius: 0;
  border: none;
  outline: none;
  background: #000d6b;
  padding: 8px 0px;
}

.car__item-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.car__item-btn a:hover {
  color: #fff;
}

.car__btn-details {
  background: #f9a826;
  border-radius: 0px 4px 4px 0px;
}

.car__btn-rent {
  border-radius: 4px 0px 0px 4px;
}

.car__item-content {
  flex-grow: 1; /* Ensure the content stretches */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure buttons stay at the bottom */
}

.car__item-content h4 {
  font-size: 1.8rem;
}

.car__item-content h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

/*Assurances items*/

.assurance__item {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  transition: box-shadow 0.3s, transform 0.3s;
  background: #fff;
  text-align: center;
}

.assurance__item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.assurance__item-content {
  text-align: center;
}

.abrv {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000d6b;
}

.assurance__item-info {
  flex-grow: 1; /* Ensure the list grows to fill the space */
}

.assurance__item-info ul {
  list-style: none;
  padding: 0;
  text-align: center;
  font-size: 1.1rem; /* Increase font size */
}

.avantage-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the items */
  margin-bottom: 10px;
  color: #333;
}

.avantage-item i {
  color: #28a745;
  margin-right: 10px;
}

.assurance__item-btn {
  background: #000d6b;
  color: white;
  padding: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  display: block;
  margin: 0 auto;
  width: 80%;
}

.assurance__item-btn:hover {
  background: #001a8d;
}

.assurance__item-btn a {
  color: white;
  text-decoration: none;
  display: block;
}


/* FIN */

@media only screen and (max-width: 992px) {
  .car__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .car__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .car__item-info {
    flex-wrap: wrap;
  }

  .car__item-info span {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .car__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .car__item h4 {
    font-size: 1rem;
  }

  .car__item h6 {
    font-size: 0.9rem;
  }

  .car__item-info span {
    font-size: 0.7rem;
  }

  .car__item-info span i {
    font-size: 0.9rem;
  }

  .car__item-btn {
    font-size: 0.8rem;
  }
}
